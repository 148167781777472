<template>
    <ul class="auth-client-list">
        <li v-for="client in clients" :key="client.id">
            <InlineSvg :src="client.icon" />
        </li>
    </ul>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
    components: {
        InlineSvg,
    },
    data() {
        return {
            clients: [
                {
                    id: 'orange',
                    icon: require('@/assets/img/Orange.svg'),
                },
                {
                    id: 'empik',
                    icon: require('@/assets/img/Empik.svg'),
                },
                {
                    id: 'born2be',
                    icon: require('@/assets/img/Born2Be.svg'),
                },
                {
                    id: 'aza',
                    icon: require('@/assets/img/Aza.svg'),
                },
                {
                    id: 'chicco',
                    icon: require('@/assets/img/Chicco.svg'),
                },
            ],
        }
    },
}
</script>

<style lang="scss">
.auth-client-list {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0 -22px 100px;
    padding: 0;

    li {
        margin: 10px 22px;
    }
}
</style>
