<template>
    <div class="app ">
        <div class="justify-content-center">
            <b-card no-body class="login-box">
                <h2 class="login-box__header">Welcome, it is nice to see you!</h2>
                <p class="login-box__subheader">Log in to get going</p>

                <b-card-body>
                    <wit-tabless-form
                        :formId="formId"
                        :inputs="inputs"
                        ref="tablessForm"
                        :valid="!$v.$invalid"
                        finish-label="Log in"
                        @input="onFormInput"
                        @finish="login"
                    >
                        <template #feedback>
                            <Feedback :state="loginError.state" :invalid="loginError.message"></Feedback>
                        </template>
                    </wit-tabless-form>
                    <p class="login-box__footer">
                        New to WitCloud? <router-link to="/auth/register">Register now!</router-link>
                    </p>
                </b-card-body>
            </b-card>
            <ClientList />
        </div>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
        <wit-notification
            v-model="showRegisteredNotification"
            title="Registered successfully!"
            description="Check Your inbox and confirm Your email prior to logging in :)"
        />
        <wit-notification
            v-model="showResetNotification"
            title="Password reset successfully!"
            description="You can now log in with your new password"
        />
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'

import Feedback from '@/components/Feedback'
import Loading from '@/components/loading.vue'
import WitNotification from '@/components/WitNotification.vue'
import WitTablessForm from '@/components/WitTablessForm.vue'
import ClientList from '@/components/Auth/ClientList.vue'

import {formMixin} from '@/mixins/formMixin'

export default {
    data() {
        return {
            loginForm: {
                email: '',
                password: '',
            },
            loginError: {
                state: true,
                message: '',
            },
            showRegisteredNotification: false,
            showResetNotification: false,
            formId: 'login-form',
        }
    },
    mixins: [formMixin, validationMixin],
    validations: {
        loginForm: {
            email: {
                required,
                email,
            },
            password: {
                required,
            },
        },
    },
    components: {
        Feedback,
        Loading,
        WitNotification,
        WitTablessForm,
        ClientList,
    },
    computed: {
        redirect() {
            return this.$route.query.redirect && decodeURIComponent(this.$route.query.redirect)
        },
        isCallback() {
            return Boolean(this.$route.query.callback)
        },
        inputs() {
            return [
                {
                    name: 'email',
                    value: this.$v.loginForm.email.$model,
                    model: this.$v.loginForm.email,
                    type: 'text',
                    inputLabel: 'Email',
                    placeholder: 'sample@email.com',
                    invalid: 'This field is required',
                },
                {
                    name: 'password',
                    value: this.$v.loginForm.password.$model,
                    model: this.$v.loginForm.password,
                    type: 'password',
                    inputLabel: 'Password',
                    placeholder: 'Type here',
                    invalid: 'This field is required',
                    showReset: true,
                },
            ]
        },
    },
    created() {
        const {registered, passwordReset} = this.$route.query
        if (registered) {
            this.showRegisteredNotification = true
        }
        if (passwordReset) {
            this.showResetNotification = true
        }

        this.$store.commit('project/SET_PROJECTS', [])
    },
    methods: {
        async login() {
            this.$store.commit('loading/PROCESSING', `Logging in...`)
            const login = await this.$auth
                .login({
                    data: {
                        email: this.loginForm.email,
                        password: this.loginForm.password,
                    },
                    redirect: localStorage.getItem('lastDesiredPath')
                        ? `${localStorage.getItem('lastDesiredPath')}?login=true`
                        : '/dashboard?login=true',
                    success() {
                        this.$store.state.project.active = null
                    },
                    fetchUser: true,
                    rememberMe: true,
                })
                .catch(e => {
                    this.$store.commit('loading/PROCESSED')
                    this.loginError.state = false
                    this.loginError.message =
                        e.response.data && e.response.data.data ? e.response.data.data : 'Could not log in'
                })
        },
        validateRef(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            return validateField.$dirty ? !validateField.$invalid : null
        },
    },
}
</script>
